/**
 * @param {string} url
 */
export const removeUtmParams = (url) => {
    const urlObj = new URL(url);
    urlObj.searchParams.delete('utm_source');
    urlObj.searchParams.delete('utm_medium');
    urlObj.searchParams.delete('utm_campaign');
    urlObj.searchParams.delete('utm_term');
    urlObj.searchParams.delete('utm_content');
    return urlObj.pathname + urlObj.search;
};

/**
 * @param {string} url
 */
export const updateHistoryState = (url) => {
    window.history.replaceState({}, '', url);
};
