import { render } from 'preact';
import { getConsent, subscribeToConsent } from '@vgno/utils';
import Snakkis from './Snakkis.jsx';

// We export a function that receives props, including "props.node"
export default async function initSnakkis(props) {
    // Ensure we have a valid HTMLElement to mount onto
    const { node } = props;
    if (!node || !(node instanceof HTMLElement)) {
        console.error('No valid node found for Snakkis');
        return;
    }

    const consent = await getConsent('CMP:personalisation');
    let initialized = false;

    if (consent) {
        return render(<Snakkis {...props} />, node);
    } else {
        subscribeToConsent('CMP:personalisation', (consented) => {
            if (consented && !initialized) {
                initialized = true;
                return render(<Snakkis {...props} />, node);
            }
        });
    }
}
