import { isVGApp, isIOS } from '../../utils/device.js';
import { hasSession } from '../../auth/schibsted-account';
import { trackEngagement } from '../pulse';
import { getConsent, subscribeToConsent } from '@vgno/utils';

const VALID_TYPES = ['bigImage', 'smallImage', 'fishfinger'];
const isAndroidApp = !isIOS() && isVGApp();
const isIOSApp = isIOS() && isVGApp();
const isWeb = !isVGApp();
export const isRelevantPlatform = (card) =>
    !(isAndroidApp && card.extras.ignore?.includes('android')) &&
    !(isIOSApp && card.extras.ignore?.includes('ios')) &&
    !(isWeb && card.extras.ignore?.includes('web'));

const slugifyText = (title) =>
    title
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9]/g, '-')
        .replace(/^-+|-+$/g, '');

let intersectionObserver;
const callbacks = new Map();

const whenVisible = (element, callback) => {
    if (!intersectionObserver) {
        intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        callbacks.get(entry.target)?.();
                        intersectionObserver.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.5 },
        );
    }

    intersectionObserver.observe(element);
    callbacks.set(element, callback);
};

const waitForConsent = () => {
    return new Promise((resolve) => {
        getConsent('CMP:marketing').then((consent) => {
            if (consent === '1') {
                resolve();
                return;
            }

            subscribeToConsent('CMP:marketing', (newConsent) => {
                if (newConsent === '1') resolve();
            });
        });
    });
};

async function trackPageView({ deferOnload }) {
    await deferOnload();

    const user = await hasSession();
    if (!user) {
        return;
    }

    const { userId } = user;
    const braze = await import('@braze/web-sdk');

    braze.initialize('d1240f31-a44b-4a02-9d5a-30677794528d', {
        baseUrl: 'sdk.fra-01.braze.eu',
        doNotLoadFontAwesome: true,
    });

    /** @type {HTMLElement[]} */
    const brazeContainers = $$('.braze-container');

    braze.changeUser(`sdrn:spid.no:user:${userId}`);

    braze.openSession();
    const shownCards = new Set();
    brazeContainers.forEach((brazeContainer) => {
        if (brazeContainer && userId) {
            braze.showContentCards(brazeContainer, function (cards) {
                if (shownCards.has(`${brazeContainer.dataset.position}`)) {
                    return;
                }
                shownCards.add(`${brazeContainer.dataset.position}`);
                const filteredCards = cards
                    .filter((card) => isRelevantPlatform(card))
                    .filter((card) => {
                        if (card.extras?.type !== 'front') {
                            return false;
                        }

                        if (isVGApp() && card.extras?.showApp === false) {
                            return false;
                        }

                        if (
                            card.extras?.position !==
                            brazeContainer.dataset.position
                        ) {
                            return false;
                        }

                        return true;
                    })
                    .slice(0, 1);

                /* This callback can run multiple times, so we check for both cases */
                if (filteredCards.length === 0) {
                    brazeContainer.hidden = true;
                    return;
                } else {
                    brazeContainer.hidden = false;
                }

                filteredCards.forEach((card) => {
                    if ('title' in card && 'linkText' in card) {
                        const title =
                            typeof card.title === 'string'
                                ? slugifyText(card.title)
                                : 'braze-card-without-title';
                        const buttonText =
                            typeof card.linkText === 'string'
                                ? slugifyText(card.linkText)
                                : 'braze-button-without-text';
                        const id = card.extras?.pulseId || title;
                        card.subscribeToClickedEvent(() => {
                            trackEngagement({
                                action: 'Click',
                                elementId: `braze-content-card:${id}:button:${buttonText}`,
                                elementName: `Braze content card action button clicked: ${card.linkText}`,
                                target: {
                                    url: card.url,
                                },
                            });
                        });

                        card.subscribeToDismissedEvent(() => {
                            trackEngagement({
                                action: 'Click',
                                elementId: `braze-content-card:${id}:dismissed`,
                                elementName: `Braze content card dismissed`,
                                target: {
                                    url: card.url,
                                },
                            });
                        });
                        whenVisible(brazeContainer, () => {
                            trackEngagement({
                                action: 'View',
                                elementId: `braze-content-card:${id}`,
                                elementName: `Braze content card presented to the user`,
                                target: {
                                    url: card.url,
                                },
                            });
                        });
                    }
                });

                filteredCards.forEach((card) => {
                    if (VALID_TYPES.includes(card?.extras.type)) {
                        brazeContainer.dataset.type = card.extras.type;
                    }
                });

                /**
                 * Only return first card for now, until we know wether
                 * we should ever show more than one
                 */
                return filteredCards;
            });
        } else {
            return;
        }
    });
}

export default {
    waitForConsent,
    trackPageView,
};
