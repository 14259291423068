import { trackEngagement } from './utils.js';

export const trackLoad = (custom) => {
    trackEngagement('Load', {
        name: 'Snakkis Chatbot',
        contentId: 'chatbot',
        custom: custom,
    });
};

export const trackImpression = (node, custom) => {
    let hasBeenInView = false;
    let inViewTimeout = null;

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                // If >= 50% of the node is in view
                if (
                    entry.isIntersecting &&
                    !hasBeenInView &&
                    entry.intersectionRatio >= 0.5
                ) {
                    // Since we have multiple thresholds, we need to clear the timeout to
                    // prevent multiple impressions, since the observer can trigger multiple times
                    if (inViewTimeout) {
                        clearTimeout(inViewTimeout);
                    }

                    inViewTimeout = setTimeout(() => {
                        hasBeenInView = true;
                        trackEngagement('View', {
                            name: 'Snakkis Chatbot',
                            contentId: 'chatbot',
                            custom: custom,
                        });
                    }, 1000);
                }
                // If user scrolls away after having been in view
                else if (!entry.isIntersecting && hasBeenInView) {
                    observer.disconnect();
                    trackEngagement('View', {
                        id: 'leave',
                        name: 'Snakkis Chatbot',
                        contentId: 'chatbot',
                        custom: custom,
                    });
                }

                // Clear the timer if no longer intersecting
                if (!entry.isIntersecting && inViewTimeout) {
                    clearTimeout(inViewTimeout);
                }
            });
        },
        {
            threshold: [0, 0.5, 1],
        },
    );

    observer.observe(node);
};
